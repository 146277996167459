<template>
	<div class="client-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !clientNo"
		         v-on:ok="deleteClient"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Client</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalClientNo" class="font-weight-bold">Client No.</label>
					<input type="text" id="modalClientNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': clientNoInvalid}"
					       v-model="clientNo"
					       v-on:input="clientNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-1 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveClient"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!client"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="clientNo" class="font-weight-bold">Client No.</label>
					<input type="text" id="clientNo" class="form-control position-relative" placeholder=""
					       v-bind:value="localClient.clientNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="handler" class="font-weight-bold">Handler</label>
					<input type="text" id="handler" class="form-control position-relative"
					       placeholder="Choose A Handler Among Your Staffs"
					       v-bind:class="invalidInputs.handler"
					       v-bind:value="localClient.handlerName" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="name" class="font-weight-bold">Name</label>
					<input type="text" id="name" placeholder="Client Name" class="form-control position-relative"
					       v-bind:class="invalidInputs.name"
					       v-bind:value="localClient.name"
					       v-on:input="updateClient('name', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="type" class="font-weight-bold">Type</label>
					<select id="type" class="form-control position-relative"
					        v-bind:class="invalidInputs.type"
					        v-bind:value="localClient.type"
					        v-on:change="updateClient('type', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>Company</option>
						<option>Individual</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="address" class="font-weight-bold">Address</label>
					<input type="text" id="address" placeholder="Client Address" class="form-control position-relative"
					       v-bind:class="invalidInputs.address"
					       v-bind:value="localClient.address"
					       v-on:input="updateClient('address', $event.target.value)">
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(contact, index) in localClient.contacts" v-bind:key="index">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localClient.contacts.length === 1"
						        v-on:click="addDeleteContacts('delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Contact {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localClient.contacts.length >= 10"
						        v-on:click="addDeleteContacts('add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`contact-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`contact-name${index}`" placeholder="Contact Name"
							       class="form-control position-relative"
							       v-bind:class="invalidContacts[index].name"
							       v-bind:value="contact.name"
							       v-on:input="updateContacts('name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`contact-phone${index}`" class="font-weight-bold">Phone</label>
							<input type="text" v-bind:id="`contact-phone${index}`" placeholder="Contact Phone Number"
							       class="form-control position-relative"
							       v-bind:class="invalidContacts[index].phone"
							       v-bind:value="contact.phone"
							       v-on:input="updateContacts('phone', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`contact-email${index}`" class="font-weight-bold">Email</label>
							<input type="text" v-bind:id="`contact-email${index}`" placeholder="Contact Email"
							       class="form-control position-relative"
							       v-bind:class="invalidContacts[index].email"
							       v-bind:value="contact.email"
							       v-on:input="updateContacts('email', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="currency" class="font-weight-bold">Currency</label>
					<select id="currency" class="form-control position-relative"
					        v-bind:class="invalidInputs.currency"
					        v-bind:value="localClient.currency"
					        v-on:change="updateClient('currency', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>HKD</option>
						<option>USD</option>
						<option>RMB</option>
						<option>JPY</option>
						<option>CAD</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="exchangeRate" class="font-weight-bold">Exchange Rate</label>
					<input type="text" id="exchangeRate" placeholder="Exchange Rate (to HKD)" class="form-control position-relative"
					       v-bind:class="invalidInputs.exchangeRate"
					       v-bind:value="localClient.exchangeRate"
					       v-on:input="updateClient('exchangeRate', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="creditLimit" class="font-weight-bold">Credit Limit</label>
					<input type="text" id="creditLimit" placeholder="Credit Limit" class="form-control position-relative"
					       v-bind:class="invalidInputs.creditLimit"
					       v-bind:value="localClient.creditLimit"
					       v-on:change="updateClient('creditLimit', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="paymentTerms" class="font-weight-bold">Payment Terms</label>
					<input type="text" id="paymentTerms" placeholder="Payment Terms (Optional)" class="form-control position-relative"
					       v-model="localClient.paymentTerms">
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localClient.notes"></textarea>
				</div>
			</form>
		</div>
		<div class="table-selects position-relative w-50 h-100 ml-3 rounded d-flex flex-column align-items-end">
			<table-select title="Staff" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="staffsFields"
			              v-bind:data-array="staffs"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localClient.handler]"
			              v-on:row-clicked="selectHandler($event)"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import {BModal} from "bootstrap-vue";
import { contactsDifferent } from "@/utility/helpers";

export default {
	name: "ClientDetails",
	
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal
	},
	
	props: {
		// props from vue router route params
		clientId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initClient();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localClient: {
				_id: "",
				clientNo: "",
				name: "",
				type: "",
				address: "",
				contacts: [
					{
						name: "",
						phone: "",
						email: "",
					},
				],
				currency: "",
				exchangeRate: "",
				creditLimit: "",
				handler: "",
				paymentTerms: "",
				notes: "",
				// added property
				handlerName: "",
			},
			invalidInputs: {
				name: "",
				type: "",
				address: "",
				currency: "",
				exchangeRate: "",
				creditLimit: "",
				handler: "",
			},
			invalidContacts: [
				{
					name: "",
					phone: "",
					email: "",
				},
			],
			staffsFields: [
				{ display: "Name", key: "name", classBinding: ["f-4"] },
				{ display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
				{ display: "Gender", key: "gender", classBinding: [] },
				{ display: "Position", key: "position", classBinding: ["f-4"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			clientNo: "",
			clientNoInvalid: false,
		}
	},
	
	computed: {
		client() {
			return this.$store.state.contents.clients.find(({ _id }) => _id === this.clientId);
		},
		
		savable() {
			// fall through pattern
			if (!this.client) return true;
			// can safely access properties inside this.client due to the first if clause in this function
			if (contactsDifferent(this.localClient.contacts, this.client.contacts)) return true;
			const keys = ["name", "type", "address", "currency", "exchangeRate", "creditLimit", "handler", "paymentTerms", "notes"];
			return keys.some(key => this.localClient[key] !== this.client[key]);
		},
		
		staffs() {
			return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
				return {
					_id: _id,
					name: `${lastName} ${firstName}, ${preferredName}`,
					staffNo: staffNo,
					gender: gender,
					position: position
				};
			});
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.clientNo = "";
				this.clientNoInvalid = false;
			}
		},
		
		initClient() {
			if (this.client) {
				const obj = {...this.client};
				obj.contacts = [];
				this.invalidContacts = [];
				this.client.contacts.forEach(contact => {
					obj.contacts.push({...contact});
					this.invalidContacts.push({ name: "", phone: "", email: "" });
				});
				const handler = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.handler);
				// added property
				obj.handlerName = handler ? `${handler.lastName} ${handler.firstName}, ${handler.preferredName} (${handler.staffNo})` : "N/A";
				this.localClient = obj;
			} else {
				this.localClient._id = uuidv4();
				this.localClient.clientNo = "CUS-" + this.localClient._id.split("-")[0].toUpperCase();
			}
		},
		
		addDeleteContacts(option, index) {
			if (option === "add") {
				this.localClient.contacts.splice(index + 1, 0, { name: "", phone: "", email: "" });
				this.invalidContacts.splice(index + 1, 0, { name: "", phone: "", email: "" });
			} else {
				this.localClient.contacts.splice(index, 1);
				this.invalidContacts.splice(index, 1);
			}
		},
		
		selectHandler(staff) {
			this.localClient.handler = staff._id;
			this.localClient.handlerName = `${staff.name} (${staff.staffNo})`;
			this.invalidInputs.handler = "";
		},
		
		updateClient(key, value) {
			this.localClient[key] = value;
			switch (key) {
				case "creditLimit":
					// nullish check is included
					if (isNumeric(value) && parseFloat(value) >= 0) {
						// bind to change event instead of input event
						this.localClient[key] = parseFloat(value).toFixed(2);
						this.invalidInputs[key] = "";
					} else {
						this.invalidInputs[key] = "invalid";
					}
					break;
				case "exchangeRate":
					this.invalidInputs[key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
					break;
				default:
					// check if value is just white spaces
					this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		updateContacts(key, value, index) {
			this.localClient.contacts[index][key] = value;
			switch (key) {
				case "phone":
					// nullish check is included
					this.invalidContacts[index][key] = isMobilePhone(value) ? "" : "invalid";
					break;
				case "email":
					// nullish check is included
					this.invalidContacts[index][key] = isEmail(value) ? "" : "invalid";
					break;
				default:
					this.invalidContacts[index][key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const clientKeys = Object.keys(this.invalidInputs);
			const contactKeys = ["name", "phone", "email"];
			clientKeys.forEach(key => {
				if (!this.localClient[key] ||
					this.localClient[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.invalidContacts.forEach((contact, index) => contactKeys.forEach(key => {
				if (!this.localClient.contacts[index][key] ||
					this.localClient.contacts[index][key].trim().length === 0) contact[key] = "invalid"; //object reference
			}));
			return clientKeys.some(key => this.invalidInputs[key] === "invalid") ||
				this.invalidContacts.some(contact => contactKeys.some(key => contact[key] === "invalid"));
		},
		
		async saveClient() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				// need not do deep copy for contacts since initClient will be run upon successful saving
				const client = {...this.localClient};
				delete client.handlerName;
				await this.$store.dispatch("contents/updateContent", { key: "clients", value: client});
				if (this.clientId) {
					this.initClient();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/client/${client._id}`);
					// need to run initClient after route change since clientId prop was undefined
					this.initClient();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Client Created!";
				}
			}
		},
		
		async deleteClient(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.clientNo !== this.localClient.clientNo) this.clientNoInvalid = true;
			} else if (this.clientNo !== this.localClient.clientNo) {
				this.clientNoInvalid = true;
				bvModalEvent.preventDefault();
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "clients", _id: this.client._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/client");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/client");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
</style>